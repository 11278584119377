/* Base container and layout */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.logo {
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
}

/* Typography */
h2 {
  font-size: 28px;
  color: #333;
  text-align: center;
}

h3 {
  font-size: 20px;
  color: #555;
  margin: 0 0 15px 0;
}

/* Welcome bar and user info */
.welcome-bar {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome-buttons {
  display: flex;
  gap: 10px;
}

.user-role {
  background-color: #f8f9fa;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #5f6368;
}

/* Files container and navigation */
.files-container {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin: 0 auto;
}

.folder-navigation {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Lists styling */
.files-list, .folders-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
}

.file-item:last-child {
  border-bottom: none;
}

.file-item span {
  flex: 1;
}

.folder-item {
  margin-bottom: 10px;
}

.folder-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2c3e50;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.1s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.folder-link:hover {
  background-color: #e0e7ff;
  transform: translateX(5px);
  text-decoration: none;
}

.folder-icon {
  margin-right: 10px;
  font-size: 20px;
}

/* Unified button styles */
.back-button,
.password-button,
.debug-button,
.restore-button,
.download-button,
.users-list-button,
.save-button,
.group-button,
.assign-folders-button,
.create-user-button,
.sign-out-button {
  background-color: #f9f9f9;
  color: #2c3e50;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

/* Specific button colors */
.users-list-button { background-color: #4285f4; color: white; }
.sign-out-button { background-color: #f44336; color: white; }
.save-button { background-color: #1a73e8; color: white; }
.assign-folders-button { background-color: #34a853; color: white; }
.create-user-button { background-color: #34a853; color: white; }

/* Button hover states */
.back-button:hover,
.password-button:hover,
.debug-button:hover,
.restore-button:hover,
.download-button:hover {
  background-color: #e0e7ff;
  transform: translateX(-5px);
}

.users-list-button:hover { background-color: #3367d6; }
.sign-out-button:hover { background-color: #d32f2f; }
.save-button:hover { background-color: #1765cc; }
.assign-folders-button:hover { background-color: #2d9048; }
.create-user-button:hover { background-color: #2d9048; }

/* Button active states */
.back-button:active,
.password-button:active,
.debug-button:active,
.restore-button:active,
.download-button:active {
  transform: translateX(-2px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Disabled button states */
.save-button:disabled,
.cancel-button:disabled,
.group-button.disabled-group {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Messages */
.error-message {
  background-color: #fdeded;
  border-left: 4px solid #d93025;
  color: #d93025;
  padding: 12px;
  margin: 12px 0;
  border-radius: 4px;
}

.info-message {
  background-color: #e8f0fe;
  border-left: 4px solid #1a73e8;
  color: #1755a6;
  padding: 12px;
  margin: 12px 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Loading states */
.loading,
.loading-users,
.loading-folders {
  text-align: center;
  padding: 20px;
  color: #666;
}

.loading-indicator {
  font-size: 13px;
  color: #5f6368;
  font-style: italic;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
}

.users-modal {
  width: auto;
  min-width: 600px;
  max-width: 90%;
}

.folders-modal {
  width: auto;
  min-width: 500px;
  max-width: 700px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.modal-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}

.close-modal {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
}

.close-modal:hover {
  color: #333;
}

/* Table styles */
.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  table-layout: auto;
}

.users-table th,
.users-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.users-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  position: sticky;
  top: 0;
}

.users-table tr:hover {
  background-color: #f5f5f5;
}

/* Group editing styles */
.editable-group {
  cursor: pointer;
  padding: 3px 6px;
  border-radius: 3px;
  transition: background-color 0.2s;
  display: inline-block;
}

.group-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.group-selector select {
  padding: 6px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  background-color: white;
  min-width: 100px;
}

.group-selector select:focus {
  outline: none;
  border-color: #4285f4;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.25);
}

/* Folder assignment styles */
.folders-checkbox-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 0;
  margin-top: 15px;
}

.folder-checkbox-item {
  margin: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #f1f1f1;
  position: relative;
}

.folder-checkbox-item:nth-child(odd) {
  background-color: #ffffff;
}

.folder-checkbox-item:nth-child(even) {
  background-color: #f8f9fa;
}

.folder-checkbox-item:last-child {
  border-bottom: none;
}

.folder-checkbox-item:hover {
  background-color: #f0f7ff;
}

.folder-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}

.folder-checkbox-label input[type="checkbox"] {
  margin: 0;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  cursor: pointer;
}

.folder-checkbox-label input[type="checkbox"]:checked + .folder-name {
  font-weight: 500;
  color: #1a73e8;
}

.folder-name {
  margin-left: 12px;
  font-size: 14px;
  flex-grow: 1;
}

/* Form styles */
.create-user-form {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input:focus {
  outline: none;
  border-color: #4285f4;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.25);
}

/* Action containers */
.modal-actions,
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

/* Loading dots animation */
.loading-dot-animation {
  position: relative;
}

.loading-dot-animation::after {
  content: '...';
  position: absolute;
  animation: loading-dots 1.5s infinite;
  margin-left: 2px;
}

@keyframes loading-dots {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
  100% { content: '.'; }
}